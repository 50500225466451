@use 'node_modules/@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use '@angular/material' as mat;


$t: mat.define-typography-config($font-family: 'Titillium Web',
$headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
$headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
$headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
$headline-4: mat.define-typography-level(34px, 40px, 400, 'Titillium Web', normal),
$headline-5: mat.define-typography-level(45px, 48px, 400, 'Titillium Web', normal),
$headline-6: mat.define-typography-level(22px, 30px, 600, 'Titillium Web', normal),
$subtitle-1: mat.define-typography-level(18px, 23.2px, 600, 'Titillium Web', normal),
$subtitle-2: mat.define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$body-1: mat.define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$body-2: mat.define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$caption: mat.define-typography-level(12px, 18px, 400, 'Titillium Web', normal),
$button: mat.define-typography-level(14px, 22px, 400, 'Titillium Web', normal));

@include spb.set-typography($t);

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: 'Titillium Web', sans-serif;
  height: 100%;
  margin: 0 auto;
}

/* use this to grow */
.spb-filler {
  display: flex;
  flex: 1 0;
}

.spb-flipper::-webkit-outer-spin-button,
.spb-flipper::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

a {
  color: #232323;
}
